<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-table :data="tableData" stripe height="calc(100% - 30px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="sn" label="编号"></el-table-column>
          <el-table-column prop="name" label="设备名" align="center"> </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"> </el-table-column>
          <el-table-column prop="account" label="使用账号" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.userName }}</p>
              <p>{{ scope.row.account }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="大屏类型" align="center" width="80"> </el-table-column>
          <el-table-column prop="state" label="状态" align="center" width="70">
            <template slot-scope="scope">
              <el-tag type="Info" v-if="scope.row.state == 0">离线</el-tag>
              <el-tag type="success" v-if="scope.row.state == 1">在线</el-tag>
              <el-tag type="danger" v-if="scope.row.state == 2">故障</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" width="150" label="最后在线时间" align="center">
            <template slot-scope="scope">{{ scope.row.lastTime | timefilters }}</template>
          </el-table-column>
          <el-table-column prop="addTime" width="150" label="首次添加时间" align="center">
            <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="280">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="editBtn(scope.row, 1)">修改</span>
              <span class="warning optionBtn" @click="editBtn(scope.row, 2)" v-if="scope.row.state < 2">报修</span>
              <span class="warning optionBtn" @click="changeState(scope.row)" v-if="scope.row.state == 2">设为正常</span>
              <span class="success optionBtn" @click="shareBtn(scope.row)">共享数据</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="修改设备信息" :visible.sync="editDialog">
      <el-form :model="formData" :rules="rules" ref="formData">
        <el-form-item label="设备名" :label-width="formLabelWidth" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formData.remark" :autosize="{ minRows: 2 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveHardwareData('formData')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="报修" :visible.sync="setDialog">
      <el-form :model="formData" :rules="rules" ref="formData">
        <el-form-item label="报修描述" :label-width="formLabelWidth" prop="malfunctionMessage">
          <el-input type="textarea" v-model="formData.malfunctionMessage" :autosize="{ minRows: 2 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveData('formData')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="共享数据" :visible.sync="shareDialog">
      <el-form :model="formData" :rules="rules" ref="formData" :label-width="formLabelWidth">
        <el-form-item label="选择用户">
          <el-transfer v-model="selectedValues" :data="userData" :titles="['查询用户', '已选用户']" v-if="shareDialog" filterable :filter-method="filterMethod" filter-placeholder="请输入用户名/账号"> </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shareDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveShareData('formData')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Hardware } from '../../../components/HospitalDomain/Hardware'
import { User } from '../../../components/HospitalDomain/User'
export default {
  name: '',
  components: {
    bread,
  },
  data() {
    var hardware = new Hardware(this.TokenClient, this.Services.Authorization)
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      HardwareDomain: hardware,
      User: user,
      tableData: [],
      selectedValues: [], // 已选中的值
      userData: [],
      transferData: [
        // 双列表的数据
        // ...更多选项
      ],
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      keyword: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      dataTotal: 0,
      formData: {
        id: 0,
        name: '',
        remark: '',
        malfunctionMessage: '',
      },
      editDialog: false,
      rules: {
        malfunctionMessage: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
      },
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      formLabelWidth: '120px',
      setDialog: false,
      shareDialog: false,
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1 || item.account.indexOf(query) > -1
    },
    getHardwareUserIds() {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.HardwareDomain.GetHardwareUserIds(
          _this.formData.sn,
          function (data) {
            resolve(data.data)
          },
          function (error) {
            console.log(error)
            resolve([])
          }
        )
      })
    },
    herbalSearch() {
      var _this = this
      _this.userData = []
      _this.User.GetUserAll(
        _this.organizationId,
        async function (data) {
          _this.userData = data.data
          _this.selectedValues = []
          _this.selectedValues = await _this.getHardwareUserIds()
          _this.userData.forEach((item) => {
            _this.$set(item, 'key', item.id)
            _this.$set(item, 'label', `${item.userName}-${item.account}`)
          })
          _this.shareDialog = true
        },
        function (error) {
          console.log(error)
        }
      )
    },
    shareBtn(item) {
      this.formData = item
      this.herbalSearch()
    },
    editBtn(item, num) {
      this.formData = item
      if (num == 1) {
        this.editDialog = true
      } else {
        this.setDialog = true
      }
    },
    saveShareData() {
      var _this = this
      let map = {
        hardwareSN: _this.formData.sn,
        userIdArr: _this.selectedValues,
      }
      _this.HardwareDomain.SetHardwareBandUser(
        map,
        function (data) {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
          _this.shareDialog = false
          _this.GetList()
        },
        function (error) {
          console.log(error)
        }
      )
    },
    changeState(item) {
      var _this = this
      let map = {
        id: item.id,
      }
      _this.HardwareDomain.SetHardwareEnable(
        map,
        function (data) {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
          _this.GetList()
        },
        function (error) {
          console.log(error)
        }
      )
    },
    saveData(e) {
      var _this = this
      _this.$refs[e].validate((val) => {
        if (val) {
          let map = {
            id: _this.formData.id,
            malfunctionMessage: _this.formData.malfunctionMessage,
          }
          _this.HardwareDomain.SetHardwareEnable(
            map,
            function (data) {
              _this.$message({
                type: 'success',
                message: '操作成功!',
              })
              _this.setDialog = false
              _this.GetList()
            },
            function (error) {
              console.log(error)
            }
          )
        }
      })
    },
    saveHardwareData(e) {
      var _this = this
      _this.$refs[e].validate((val) => {
        if (val) {
          let map = {
            id: _this.formData.id,
            name: _this.formData.name,
            remark: _this.formData.remark,
          }
          _this.HardwareDomain.EditHardwareInfo(
            map,
            function (data) {
              _this.$message({
                type: 'success',
                message: '操作成功!',
              })
              _this.editDialog = false
              _this.GetList()
            },
            function (error) {
              console.log(error)
            }
          )
        }
      })
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    GetList() {
      var _this = this
      _this.HardwareDomain.GetHardwareList(
        _this.organizationId,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.dataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
:deep() .el-transfer-panel {
  width: auto;
}
</style>
