let Hardware = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 余额列表
     * @param type
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetHardwareList = function (orgId, pageIndex, success, error) {
        var url = ServiceBaseAddress + `/api/Hardware/GetHardwareList/${orgId}`
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }

    this.SetHardwareEnable = function (item, success, error) {
        var url = ServiceBaseAddress + `/api/Hardware/SetHardwareEnable`
        TokenClient.Post(
            url,
            true,
            { "Content-Type": "application/json" },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.EditHardwareInfo = function (item, success, error) {
        var url = ServiceBaseAddress + `/api/Hardware/EditHardwareInfo`
        TokenClient.Post(
            url,
            true,
            { "Content-Type": "application/json" },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 硬件设备管理--获取指定大屏下可查看大屏数据的医生id
     * @param {*} hardwareSN 
     * @param {*} success 
     * @param {*} error 
     */
    this.GetHardwareUserIds = function (hardwareSN, success, error) {
        var url = ServiceBaseAddress + `/api/Hardware/UserIds/${hardwareSN}`
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }
    /**
     * 硬件设备管理--设置可查看大屏数据医生账号
     * @param {*} success 
     * @param {*} error 
     */
    this.SetHardwareBandUser = function (item, success, error) {
        var url = ServiceBaseAddress + `/api/Hardware/SetHardwareBandUser`
        TokenClient.Post(
            url,
            true,
            { "Content-Type": "application/json" },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }



}

export { Hardware }
